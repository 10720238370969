import {createSlice} from '@reduxjs/toolkit'
import {REDUX_SLICES} from "../constant";


const initialState = {
    isLoading: false,
}
export const appSlice = createSlice({
    name: REDUX_SLICES.APP,
    initialState,
    reducers: {
        setLoading: (state,{payload}) => {
            state.isLoading = payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const { setLoading } = appSlice.actions

export default appSlice.reducer
