import {configureStore} from '@reduxjs/toolkit'
import {appReducer, authenticationReducer, userReducer} from './slices'
import {REDUX_SLICES} from "./constant";

export const store = configureStore({
    reducer: {
        [REDUX_SLICES.APP]: appReducer,
        [REDUX_SLICES.USER]: userReducer,
        [REDUX_SLICES.AUTHENTICATION]: authenticationReducer
    },
})