import httpService from './http.service';
import config from '../environment/config';

const base_url = `${config.api_url}/payments`;

class PaymentService {
    async setupPaymentUser (token, modelId, modelName, data) {
        const url = base_url + `/setup-payment-user?modelId=${modelId}&modelName=${modelName}`;
        return httpService.post(url, data)
    }

    async uploadKycDoc (token, modelId, modelName) {
        const url = base_url + '/setup-payment-user?token=${token}&modelId=${modelId}&modelName=${modelName}';
        return httpService.post(url)
    }
}

export default new PaymentService()
