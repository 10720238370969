import React, {useEffect, useState} from 'react'
import challengeService from '../../../services/challenge.service';
import MetaTags from 'react-meta-tags';
import './styles.scss';

export default function ChallengeDetail(props) {
    const [state, setState] = useState({
        challInst: {
            name: '',
            description: '',
            photo: []
        }
    })


    useEffect(async () => {
        const qsParams = this.props.match.params;
        const challenge = await challengeService.getById(qsParams.id);
        this.setState({challInst: challenge});
    }, [])


    const {challInst} = state;
    return (
        <MetaTags>
            <title>{challInst.name}</title>
            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:site" content="@idareu"/>
            <meta name="twitter:title" content={challInst.name}/>
            <meta name="twitter:description" content={challInst.description}/>
            <meta name="twitter:image" content={challInst.photo[0]}/>

            <meta property="og:url" content={challInst.url}/>
            <meta property="og:type" content={challInst.type}/>
            <meta property="og:title" content={challInst.name}/>
            <meta property="og:description" content={challInst.description}/>
            <meta property="og:image" content={challInst.photo[0]}/>
        </MetaTags>
    );
}

