import {Button, Grid, TextField} from "@material-ui/core"
import userService from '../../../services/user.service';
import React, {useState} from 'react'
import {yupResolver} from '@hookform/resolvers/yup'
import Layout from '../../Template/DefaultLayout';
import './index.scss';
import useQuery from "../../../hooks/useQuery";
import {Controller, useForm} from 'react-hook-form'
import * as yup from "yup";
import swalService from "../../../services/swal.service";
import {useHistory} from "react-router-dom";

export default function ResetPassword(props) {
    const formSchema = yup.object().shape({
        newPassword: yup.string().required('Password is required'),
        confirmPassword: yup.string()
            .required('Confirm password is required')
            .oneOf([yup.ref('newPassword')], 'Password does not match')
    })
    const history = useHistory();
    const formOptions = {
        resolver: yupResolver(formSchema),
        defaultValues: {
            newPassword: '',
            confirmPassword: ''
        } }
    const qsParams = useQuery();
    const {token} = qsParams;
    const {handleSubmit, control, formState} = useForm(formOptions);
    const {errors} = formState;
    const [state, setState] = useState({
        token,
    })

    // Redirect to homepage if token is empty.
    if (!token) {
        history.push('/')
    }

    const onSubmit = (data) => {
        const {newPassword, confirmPassword} = data;
        userService.resetPassword({newPassword: newPassword}, token).then((res) => {
            swalService.success('Reset password successfully').then(() => {
                history.push("/");
            })
        }).catch((error) => {
            swalService.error(error.message);
        })

    }

    return (

        <Layout>
            <Grid className="root" container xs={12} sm={12} md={7} lg={12}>
                    <Grid item xs={12} lg={12}>
                        <div className="title">
                            Reset Password Form
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <div className="reset-password-form">
                            <form  onSubmit={handleSubmit(onSubmit)}>
                                <Grid item xs={12}>
                                    <Controller
                                        control={control}
                                        name="newPassword"
                                        render={({field}) => (
                                            <TextField
                                                {...field}
                                                label="New password"
                                                fullWidth
                                                variant="outlined"
                                                error={!!errors.newPassword}
                                                helperText={errors?.newPassword?.message}
                                            />)}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Controller
                                        control={control}
                                        name="confirmPassword"
                                        render={({field}) => (
                                            <TextField
                                                {...field}
                                                label="Confirm password"
                                                fullWidth
                                                variant="outlined"
                                                error={!!errors.confirmPassword}
                                                helperText={errors?.confirmPassword?.message}
                                            />)}/>
                                </Grid>
                                <div className="button-box">
                                    <Button className="submit-button btn" type="submit" color="success" variant="contained">Submit</Button>
                                </div>
                            </form>
                        </div>
                    </Grid>
            </Grid>
        </Layout>
    )
}
