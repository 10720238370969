import React from 'react'
import {GridRow} from 'semantic-ui-react';
import Layout from '../Template/DefaultLayout';
import {Box, Typography} from '@material-ui/core'
import LogoImage from '../../assets/icons/icon.png'

const SharingContainer = (props) => {

    // const [needToClose,setNeedToClose] = useState(false)
    // const [html,setHtml] = useState()

    // useEffect(()=>{
    //     const file = props.match.params.challenge_file;
    //     const challenge_id = file.split('.')[0]
    //     console.log(challenge_id);
    //     window.onload = function() {
    //         window.location = `idareu://challenge/${challenge_id}`;
    //         setTimeout(()=>{
    //             window.close();
    //             setNeedToClose(true)
    //         }, 1000);
    //       }
        // const html = Axios({
        //     url:'https://api-dev.idareyou.dirox.dev/sharing/5fcde4188d4f6ff6d776fdbc.html',
        //     method: 'GET',
        //     responseType: 'blob'
        // }).then(response => {
        //     return response.data.text()
        // }).then(html=>{
        //     const dom_html = new DOMParser().parseFromString(html,'text/xml')
        //     console.log({_html: dom_html})
        //     setHtml({__html: html})
        // })
    // },[])
    return  (
    <Layout>
        <GridRow>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" maxWidth={700} margin="auto" minHeight={500}>
                <img style={{width:"100px", marginBottom:"50px"}} src={LogoImage}></img>
                <Typography variant="h3" align="center">URL not found!</Typography>
                {/* <Typography variant="h4" align="center">Download the App to view the challenge</Typography>
                <Box marginBottom="30px" display="flex"  marginTop="50px" justifyContent="center" maxWidth={100} alignItems="center">
                    <img style={{width:"", marginRight:"50px"}} src={AppstoreIcon}></img>
                    <img style={{width:""}} src={ChplayIcon}></img>
                </Box> */}

            </Box>
        </GridRow>
    </Layout>
    )
}

export default SharingContainer