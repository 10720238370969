import React, {useEffect, useRef, useState} from 'react'
import {useHistory} from 'react-router-dom';
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    Grid,
    Input,
    InputLabel,
    ListItemText,
    makeStyles,
    MenuItem,
    Select,
    SvgIcon,
    TextField,
    Typography,
} from "@material-ui/core"
import Layout from "../../Template/DefaultLayout";
import DateFnsUtils from '@date-io/date-fns';
import countryList from 'react-select-country-list'
import {KeyboardDatePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';


import {isEmail, isEmpty} from "../../../common/customValidator";
import v from 'validator';
import charityCategoriesService from "../../../services/charity-categories.service";
import charityOrgService from "../../../services/charity-org.service";
import fileService from "../../../services/file.service";
import swalService from '../../../services/swal.service'
import paymentService from "../../../services/payment.service";
import {ReactComponent as DownloadIcon} from "../../../assets/icons/download.svg"
import {FILE_CONTAINERS, KYC_STATUSES, MANGO_PAY_TERM_CONDITIONS_URL, REDUX_SLICES} from "../../../constant";
import {setLoading} from "../../../slices/app.slice";
import {useDispatch, useSelector} from "react-redux";
import {getReduxState} from "../../../slices";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        "& .MuiSelect-select:focus": {
            backgroundColor: '#FFF',
        },
        backgroundColor: '#F5F8FD'
    },
    banner: {
        backgroundColor: '#F5F8FD',
        minHeight: '230px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    title: {
        fontSize: '19px',
        fontWeight: 400,
        marginTop: theme.spacing(1),
        color: '#646464'
    },
    form: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1, 0, 0, 0),
            width: '90%',
        },
        '& .MuiFormControl-root': {
            '& .MuiInputLabel-formControl': {
                fontSize: '16px'
            },
            '& .MuiInputBase-input': {
                fontSize: '16px'
            },
            margin: theme.spacing(5, 0, 0, 0),
            width: '90%'
        },
        padding: theme.spacing(4, 2, 2, 2),
        border: '6px solid #F5F8FD',
    },
    textArea: {
        '& .MuiFormControl-root': {
            margin: theme.spacing(0, 0, 0, 0),
        },
        margin: theme.spacing(8, 0, 0, 0)
    },
    section: {
        display: 'flex',
        justifyContent: "space-between",
        marginTop: theme.spacing(4),
    },
    sectionTitle: {
        fontWeight: 600,
        fontSize: '22px'
    },
    warning: {
        color: '#FFA500',
        textAlign: 'end'
    },
    sectionContainer: {
        marginTop: theme.spacing(4)
    },
    nativeSelect: {
        margin: theme.spacing(1, 0, 0, 0),
        width: '80%',
    },
    charityAvatarSelect: {
        marginTop: theme.spacing(4) + 'px !important',
        width: '100% !important',
    },
    submitButton: {
        width: '12em',
        height: '3em',
        fontSize: '16px'
    },
    imageBox: {
        cursor: 'pointer',
        maxWidth: '230px !important',
        height: '250px',
        minHeight: '250px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#F5F5F5',
        width: '100%',
    },
    svgIcon: {
        minWidth: '30px',
        minHeight: '30px'
    },
    image: {
        objectFit: 'cover',
        objectPosition: 'center',
        width: '100%',
        maxHeight: '100%'
    },
    header: {
        color: '#5D5D5D',
        fontSize: '4rem',
        textAlign: 'center'
    },
    subHeader: {
        textAlign: 'center'
    },
    proofTitle: {
        fontSize: '19px',
        fontWeight: 400,
        marginTop: theme.spacing(1),
        color: '#646464',
        minHeight: "25%"
    },
    termConditions: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    termConditionCheckBox: {
        '& .MuiCheckbox-colorSecondary': {
            '& .Mui-checked': {
                color: '#FFA50 !important'
            }
        }

    }
}));
export default function EditCharity(props) {
    const classes = useStyles();
    const history = useHistory();
    let datePickerMaxDate = new Date();
    datePickerMaxDate.setDate(datePickerMaxDate.getDate() -1);
    const {isLoading} = getReduxState(REDUX_SLICES.APP, useSelector);
    const dispatch = useDispatch();
    const [accessToken, setAccessToken] = useState("");
    const [charityInfo, setCharityInfo] = useState({
        avatar: {
            url: ''
        },
        kycIdentityProof: {
            url: ''
        },
        kycAssociationArticlesProof: {
            url: ''
        },
        kycRegistrationProof: {
            url: ''
        },
        categoryIds: [],
    });
    const [charityCategories, setCharityCategories] = useState([]);
    const kycAssociationArticlesProofRef = useRef(null);
    const kycIdentityProofRef = useRef(null);
    const kycRegistrationProofRef = useRef(null);
    const avatarRef = useRef(null);
    const [countries, setCountries] = useState({});
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [country, setCountry] = useState("");
    const [bankAccountCountry, setBankAccountCountry] = useState("");
    const [bankAccountOwnerAddress, setBankAccountOwnerAddress] = useState("");
    const [bankAccountCity, setBankAccountCity] = useState("");
    const [bankAccountOwnerName, setBankAccountOwnerName] = useState("");
    const [bankAccountIdentifierCode, setBankAccountIdentifierCode] = useState("");
    const [bankAccountNumber, setBankAccountNumber] = useState("");
    const [bankAccountPostalCode, setBankAccountPostalCode] = useState("");
    const [birthday, setBirthday] = useState(Math.round(datePickerMaxDate.valueOf()));
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [nationality, setNationality] = useState("");
    const [countryOfResidence, setCountryOfResidence] = useState("");
    const [companyNumber, setCompanyNumber] = useState("");
    const [email, setEmail] = useState("");
    const [isTermConditionChecked, setIsTermConditionChecked] = useState(false)
    const initData = async () => {
        setCountries(countryList().getValueList())
        const queryString = props.location.search.substring(1);
        const qsParams = new URLSearchParams(queryString);
        const token = qsParams.get('token');
        // if (!token) {
        //     await swalService.error('Your session has been expired. Please contact admin for more information')
        //     history.push({
        //         pathname: '/',
        //     })
        // }
        /**
         * Set default country for countries select
         */
        setBankAccountCountry('FR');
        setNationality('FR');
        setCountryOfResidence('FR');
        setCountry('FR');
        setAccessToken(token);
        const email = qsParams.get('email');
        const data = await charityCategoriesService.get();
        setCharityCategories(data);
        const res = await charityOrgService.findWithEmail(email, token);
        const charity = res[0];
        setCharityInfo({
            id: charity.id,
            name: charity.name,
            email: charity.email,
            avatar: {
                url: charity.avatar,
            },
            kycIdentityProof: {
                url: charity.kycIdentityProof
            },
            kycAssociationArticlesProof: {
                url: charity.kycAssociationArticlesProof
            },
            kycRegistrationProof: {
                url: charity.kycRegistrationProof
            },
            categoryIds: charity.categoryIds,
            description: charity.description
        })
    }
    const getCountrySelect = () => {
        const defaultCountryCodes = ['fr'];
        return defaultCountryCodes.map((code) => {
            return (<MenuItem key={code.toUpperCase()} value={code.toUpperCase()}>{countries[code]}</MenuItem>)
        })
    }

    const getSelectedCategoryNames = (categoryIds) => {
        return charityCategories.filter(c => categoryIds
            .includes(c.id))
            .map(c => c.name.fr ? c.name.fr : c.name)
    }
    const isDisabledSubmitButton = () => {
        return (
            isEmpty(charityInfo.name) ||
            isEmpty(charityInfo.email) || !isEmail(charityInfo.email) ||
            isEmpty(charityInfo.description) ||
            isEmpty(charityInfo.avatar.url) ||
            charityInfo.categoryIds.length === 0 ||
            isEmpty(bankAccountOwnerAddress) ||
            isEmpty(address) ||
            isEmpty(city) ||
            isEmpty(bankAccountCity) || !v.matches(bankAccountPostalCode, "^[0-9]*$") ||
            isEmpty(bankAccountPostalCode) ||
            isEmpty(postalCode) ||
            isEmpty(bankAccountCountry) ||
            isEmpty(country) ||
            isEmpty(bankAccountOwnerName) ||
            isEmpty(bankAccountIdentifierCode) ||
            isEmpty(bankAccountNumber) ||
            isEmpty(firstName) || isEmpty(lastName) ||
            isEmpty(nationality) || isEmpty(countryOfResidence) ||
            isEmpty(companyNumber) || !v.matches(companyNumber, "^[0-9]*$") ||
            isEmpty(email) || !isEmail(email)) ||
            isEmpty(charityInfo.kycIdentityProof.url) ||
            isEmpty(charityInfo.kycRegistrationProof.url) ||
            isEmpty(charityInfo.kycAssociationArticlesProof.url) ||
            !isTermConditionChecked
    }
    const handleOnClickFile = (ref) => {
        ref.current.click();
    }
    const handOnChangeAvatar = (files) => {
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onloadend = (readerEvt) => {
            setCharityInfo({...charityInfo, avatar: {url: reader.result, data: files[0]}});
        };
    }
    const handleOnChangeProof = async (files, key) => {
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        if (files[0]) {
            const fileSize = Math.round(files[0].size / 1000);
            if (fileSize < 32 || fileSize > 10000) {
                await swalService.error('Your file is too small or too large to be read, must be between 32KB and 10MB')
                files = []
                return;
            }
        }
        reader.onloadend = (readerEvt) => {
            const newCharityInfo = {...charityInfo};
            newCharityInfo[key] = {
                url: reader.result,
                data: files[0],
                fileType: files[0].type,
                fileName: files[0].name
            };
            setCharityInfo(newCharityInfo);
        };
    }

    const handleSelectCategories = (e) => {
        const selectedValue = e.target.value;
        console.log('Change', selectedValue);
        setCharityInfo({...charityInfo, categoryIds: selectedValue });
    }
    const uploadAvatarImages = async () => {
        try {
            if (!charityInfo.avatar.data) {
                return new Promise((resolve, reject) => {
                    resolve(charityInfo.avatar.url);
                });
            }
            return fileService.uploadFile(FILE_CONTAINERS.CHARITY_ORG, charityInfo.avatar.data, 'avatar');
        } catch (error) {
            let alertData = {
                title: 'Upload avatar failed',
            }
            if (error.response) {
                alertData = {
                    ...alertData,
                    title: error.response.data.error.message,
                    text: error.response.data.error.stack
                }
            }
            await swalService.error(alertData.title, alertData.text || '')
        }
    }
    const uploadKycImages = async () => {
        try {
            const files = [charityInfo.kycIdentityProof.data, charityInfo.kycAssociationArticlesProof.data, charityInfo.kycRegistrationProof.data];
            return fileService.uploadMultipleFile(FILE_CONTAINERS.KYC, files, 'kyc');
        } catch (error) {
            let alertData = {
                title: 'Upload kyc documents failed',
            }
            if (error.response) {
                alertData = {
                    ...alertData,
                    title: error.response.data.error.message,
                    text: error.response.data.error.stack
                }
            }
            await swalService.error(alertData.title, alertData.text || '')
        }
    }
    const getPdfOrImageElements = (proof, key, ref) => {
        return (<Box className={classes.imageBox} onClick={() => {
            handleOnClickFile(ref)
        }}>
            <input type="file" accept="image/png, image/jpeg, image/jpg, application/pdf" style={{display: 'none'}}
                   ref={ref} onChange={(e) => {
                handleOnChangeProof(e.target.files, key)
            }}/>
            {proof.url ?
                (proof.fileType === "application/pdf" ?
                    <div>
                        <Typography className={classes.title}>{proof.fileName}</Typography>
                    </div>
                    :
                    <img className={classes.image} src={proof.url}/>) :
                <SvgIcon className={classes.svgIcon}>
                    <DownloadIcon/>
                </SvgIcon>}
        </Box>)
    }
    const handleOnSubmit = async (e) => {
        e.preventDefault();
        const mangoPayKycInfo = {
            userName: firstName + ` ${lastName}`,
            firstName,
            lastName,
            email,
            birthday: Math.round(birthday / 1000),
            nationality,
            countryOfResidence,
            companyNumber,
            address: {
                addressLine1: address,
                city,
                postalCode,
                country
            }
        }

        const mangoPayBankAccountInfo = {
            ownerAddress: {
                addressLine1: bankAccountOwnerAddress,
                city: bankAccountCity,
                postalCode: bankAccountPostalCode
            },
            ownerName: bankAccountOwnerName,
            country: bankAccountCountry,
            bic: bankAccountIdentifierCode,
            accountNumber: bankAccountNumber
        }

        let payload = {};
        const charityId = charityInfo.id;
        delete payload.id;
        dispatch(setLoading(true));
        const avatarUrl = await uploadAvatarImages();
        payload.avatar = avatarUrl ? avatarUrl : charityInfo.avatar.url;
        const kycImages = await uploadKycImages();
        payload.kycRegistrationProof = kycImages[2] ? kycImages[2] : charityInfo.kycRegistrationProof.url;
        payload.kycAssociationArticlesProof = kycImages[1] ? kycImages[1] : charityInfo.kycAssociationArticlesProof.url;
        payload.kycIdentityProof = kycImages[0] ? kycImages[0] : charityInfo.kycIdentityProof.url;
        payload.categoryIds = charityInfo?.categoryIds || [];
        payload.kycStatus = KYC_STATUSES.BEING_REVIEWED;
        try {
            await charityOrgService.updateSelf(payload);
        } catch (error) {
            dispatch(setLoading(false))
            let alertData = {
                title: 'Setup payment user failed',
            }
            if (error.response) {
                alertData = {
                    ...alertData,
                    title: error.response.data.error.message,
                    text: error.response.data.error.stack
                }
            }
            await swalService.error(alertData.title, alertData.text ? alertData.text : '')
        }
        await paymentService.setupPaymentUser(accessToken, charityId, 'charityorg', {
            mangoPayKycInfo,
            mangoPayBankAccountInfo
        })
        dispatch(setLoading(false))
        await swalService.success('Upload Kyc document and create payment user with bank account successfully')
        history.push({
            pathname: '/',
        })
    }
    useEffect(() => {
        initData();
    }, [])
    return (
        <Layout>
            {/*<Grid className="root" container xs={12} sm={12} md={7} lg={12}>*/}
            {/*    <Grid item xs={12}>*/}
            {/*        <Typography className={classes.header}>Register to iDareYou®</Typography>*/}
            {/*    </Grid>*/}
            {/*    <Grid item xs={12} className={classes.banner}>*/}
            {/*        <div>*/}
            {/*            <Typography*/}
            {/*                style={{color: '#5D5D5D', fontSize: '21px', textAlign: 'center'}}>iDareYou®</Typography>*/}
            {/*            <Typography style={{color: '#5D5D5D', fontSize: '32px'}}>Application form</Typography>*/}
            {/*        </div>*/}
            {/*    </Grid>*/}
            {/*    <form className={classes.form} onSubmit={(e) => {*/}
            {/*        handleOnSubmit(e)*/}
            {/*    }}>*/}
            {/*        <Grid container>*/}
            {/*            <MuiPickersUtilsProvider utils={DateFnsUtils}>*/}
            {/*                <Grid item xs={12} className={classes.sectionTitle}>*/}
            {/*                    <Typography className={classes.title}>Basic Information</Typography>*/}
            {/*                    <Typography className={classes.warning}>All fields are required</Typography>*/}
            {/*                </Grid>*/}

            {/*                <Grid item xs={6}>*/}
            {/*                    <TextField error={isEmpty(charityInfo.name)} value={charityInfo.name || ''}*/}
            {/*                               onChange={(e) => {*/}
            {/*                                   setCharityInfo({...charityInfo, name: e.target.value})*/}
            {/*                               }} label="Charity/PRO Name" placeHolder="Charity/PRO Name"/>*/}
            {/*                </Grid>*/}
            {/*                <Grid item xs={6}>*/}
            {/*                    <TextField*/}
            {/*                        error={isEmpty(charityInfo.email) || !isEmail(charityInfo.email)}*/}
            {/*                        value={charityInfo.email || ''} label="Charity/PRO Email" placeHolder="Charity/PRO Email"*/}
            {/*                    />*/}
            {/*                </Grid>*/}
            {/*                <Grid item xs={6}>*/}
            {/*                    <FormControl className={classes.nativeSelect}>*/}
            {/*                        <InputLabel>Categories of the charity</InputLabel>*/}
            {/*                        <Select*/}
            {/*                            multiple*/}
            {/*                            error={charityInfo.categoryIds.length === 0}*/}
            {/*                            onChange={handleSelectCategories}*/}
            {/*                            value={charityCategories.filter(c => charityInfo.categoryIds.includes(c.id)).map(c => c.name.en ? c.name.en : c.name)}*/}
            {/*                            input={<Input/>}*/}
            {/*                            renderValue={(selected) => selected.join(', ')}*/}
            {/*                        >*/}
            {/*                            {charityCategories.map((category) => (*/}
            {/*                                <MenuItem key={category.name.en ? category.name.en : category.name}*/}
            {/*                                          value={category.id}>*/}
            {/*                                    <Checkbox checked={charityInfo.categoryIds.includes(category.id)}/>*/}
            {/*                                    <ListItemText*/}
            {/*                                        primary={category.name.en ? category.name.en : category.name}/>*/}
            {/*                                </MenuItem>*/}
            {/*                            ))}*/}
            {/*                        </Select>*/}
            {/*                    </FormControl>*/}
            {/*                </Grid>*/}
            {/*                <Grid item xs={6} className={classes.textArea}>*/}
            {/*                    <Typography style={{fontSize: 16}}>Description</Typography>*/}
            {/*                    <TextField*/}
            {/*                        error={isEmpty(charityInfo.description)}*/}
            {/*                        value={charityInfo.description}*/}
            {/*                        onChange={(e) => {*/}
            {/*                            setCharityInfo({...charityInfo, description: e.target.value})*/}
            {/*                        }}*/}
            {/*                        placeholder="Description"*/}
            {/*                        variant="outlined"*/}
            {/*                        multiline*/}
            {/*                        minRows={4}*/}
            {/*                        maxRows={4}*/}
            {/*                    />*/}
            {/*                </Grid>*/}
            {/*                <Grid item xs={12}>*/}
            {/*                    <FormControl className={clsx(classes.nativeSelect, classes.charityAvatarSelect)}>*/}
            {/*                        <Grid item xs={12} className={classes.sectionTitle}>*/}
            {/*                            <Typography className={classes.title}>Charity Profile Picture</Typography>*/}
            {/*                            <Typography className={classes.warning}>All fields are required</Typography>*/}
            {/*                        </Grid>*/}
            {/*                        <Grid container className={classes.sectionContainer}>*/}
            {/*                            <Box className={classes.imageBox} onClick={() => {*/}
            {/*                                handleOnClickFile(avatarRef)*/}
            {/*                            }}>*/}
            {/*                                <input type="file"*/}
            {/*                                       accept="image/png, image/jpeg, image/jpg, application/pdf"*/}
            {/*                                       style={{display: 'none'}} ref={avatarRef} onChange={(e) => {*/}
            {/*                                    handOnChangeAvatar(e.target.files)*/}
            {/*                                }}/>*/}
            {/*                                {charityInfo.avatar.url ? (*/}
            {/*                                        <img className={classes.image} src={charityInfo.avatar.url}/>) :*/}
            {/*                                    <SvgIcon className={classes.svgIcon}>*/}
            {/*                                        <DownloadIcon/>*/}
            {/*                                    </SvgIcon>}*/}
            {/*                            </Box>*/}
            {/*                        </Grid>*/}
            {/*                    </FormControl>*/}

            {/*                </Grid>*/}
            {/*                <Grid item xs={12} className={classes.sectionTitle}>*/}
            {/*                    <Typography className={classes.title}>Bank Account Information</Typography>*/}
            {/*                    <Typography className={classes.warning}>All fields are required</Typography>*/}
            {/*                </Grid>*/}
            {/*                <Grid container className={classes.sectionContainer}>*/}
            {/*                    <Grid item xs={6}>*/}
            {/*                        <TextField error={isEmpty(bankAccountOwnerAddress)} value={bankAccountOwnerAddress}*/}
            {/*                                   onChange={(e) => {*/}
            {/*                                       setBankAccountOwnerAddress(e.target.value)*/}
            {/*                                   }} label="Address"/>*/}
            {/*                    </Grid>*/}
            {/*                    <Grid item xs={6}>*/}
            {/*                        <TextField error={isEmpty(bankAccountCity)} onChange={(e) => {*/}
            {/*                            setBankAccountCity(e.target.value)*/}
            {/*                        }} label="City"/>*/}
            {/*                    </Grid>*/}
            {/*                    <Grid item xs={6}>*/}
            {/*                        <TextField*/}
            {/*                            error={isEmpty(bankAccountPostalCode) || !v.matches(bankAccountPostalCode, "^[0-9]*$")}*/}
            {/*                            onChange={(e) => {*/}
            {/*                                setBankAccountPostalCode(e.target.value)*/}
            {/*                            }} label="Bank Account Postalcode"/>*/}
            {/*                    </Grid>*/}
            {/*                    <Grid item xs={6}>*/}
            {/*                        <FormControl className={classes.nativeSelect} error={isEmpty(bankAccountCountry)}>*/}
            {/*                            <InputLabel>Country</InputLabel>*/}
            {/*                            <Select*/}
            {/*                                label="Country"*/}
            {/*                                value={bankAccountCountry}*/}
            {/*                                onChange={(e) => {*/}
            {/*                                    setBankAccountCountry(e.target.value.toUpperCase())*/}
            {/*                                }}*/}
            {/*                            >*/}
            {/*                                {getCountrySelect()}*/}
            {/*                            </Select>*/}
            {/*                        </FormControl>*/}
            {/*                    </Grid>*/}
            {/*                    <Grid item xs={6}>*/}
            {/*                        <TextField label="Owner Name" error={isEmpty(bankAccountOwnerName)}*/}
            {/*                                   value={bankAccountOwnerName}*/}
            {/*                                   onChange={(e) => {*/}
            {/*                                       setBankAccountOwnerName(e.target.value)*/}
            {/*                                   }}*/}
            {/*                                   autoComplete="on"*/}
            {/*                        />*/}
            {/*                    </Grid>*/}
            {/*                    <Grid item xs={6}>*/}
            {/*                        <TextField label="BIC" error={isEmpty(bankAccountIdentifierCode)}*/}
            {/*                                   value={bankAccountIdentifierCode}*/}
            {/*                                   onChange={(e) => {*/}
            {/*                                       setBankAccountIdentifierCode(e.target.value)*/}
            {/*                                   }}*/}
            {/*                                   autoComplete="on"*/}
            {/*                        />*/}
            {/*                    </Grid>*/}
            {/*                    <Grid item xs={6}>*/}
            {/*                        <TextField label="IBAN" error={isEmpty(bankAccountNumber)}*/}
            {/*                                   value={bankAccountNumber}*/}
            {/*                                   onChange={(e) => {*/}
            {/*                                       setBankAccountNumber(e.target.value)*/}
            {/*                                   }}*/}
            {/*                                   autoComplete="on"*/}
            {/*                        />*/}
            {/*                    </Grid>*/}
            {/*                </Grid>*/}
            {/*                <Grid item xs={12} className={classes.sectionTitle}>*/}
            {/*                    <Typography className={classes.title}>Kyc Information</Typography>*/}
            {/*                    <Typography className={classes.warning}>All fields are required</Typography>*/}
            {/*                </Grid>*/}

            {/*                <Grid container className={classes.sectionContainer}>*/}
            {/*                    <Grid item xs={6}>*/}
            {/*                        <TextField error={isEmpty(address)} value={address} onChange={(e) => {*/}
            {/*                            setAddress(e.target.value)*/}
            {/*                        }} label="Address"/>*/}
            {/*                    </Grid>*/}
            {/*                    <Grid item xs={6}>*/}
            {/*                        <TextField error={isEmpty(city)} onChange={(e) => {*/}
            {/*                            setCity(e.target.value)*/}
            {/*                        }} label="City"/>*/}
            {/*                    </Grid>*/}
            {/*                    <Grid item xs={6}>*/}
            {/*                        <TextField error={isEmpty(postalCode) || !v.matches(postalCode, "^[0-9]*$")}*/}
            {/*                                   onChange={(e) => {*/}
            {/*                                       setPostalCode(e.target.value)*/}
            {/*                                   }} label="Postal Code"/>*/}
            {/*                    </Grid>*/}
            {/*                    <Grid item xs={6}>*/}
            {/*                        <FormControl className={classes.nativeSelect} error={isEmpty(country)}>*/}
            {/*                            <InputLabel>Country</InputLabel>*/}
            {/*                            <Select*/}
            {/*                                label="Country"*/}
            {/*                                value={country}*/}
            {/*                                onChange={(e) => {*/}
            {/*                                    setCountry(e.target.value.toUpperCase())*/}
            {/*                                }}*/}
            {/*                            >*/}
            {/*                                {getCountrySelect()}*/}
            {/*                            </Select>*/}
            {/*                        </FormControl>*/}
            {/*                    </Grid>*/}
            {/*                    <Grid item xs={6}>*/}
            {/*                        <TextField label="First Name" error={isEmpty(firstName)}*/}
            {/*                                   onChange={(e) => {*/}
            {/*                                       setFirstName(e.target.value)*/}
            {/*                                   }}*/}
            {/*                        />*/}
            {/*                    </Grid>*/}
            {/*                    <Grid item xs={6}>*/}
            {/*                        <TextField label="Last Name" error={isEmpty(lastName)}*/}
            {/*                                   onChange={(e) => {*/}
            {/*                                       setLastName(e.target.value)*/}
            {/*                                   }}*/}
            {/*                        />*/}
            {/*                    </Grid>*/}
            {/*                    <Grid item xs={6}>*/}
            {/*                        <KeyboardDatePicker*/}
            {/*                            id="date-picker-dialog"*/}
            {/*                            input*/}
            {/*                            value={birthday}*/}
            {/*                            onChange={(date) => {*/}
            {/*                                setBirthday(new Date(date).valueOf())*/}
            {/*                            }}*/}
            {/*                            maxDate={datePickerMaxDate}*/}
            {/*                            format="dd/MM/yyyy"*/}
            {/*                            margin="normal"*/}
            {/*                            label="Birthday"*/}
            {/*                            InputProps={{readOnly: true}}*/}
            {/*                            KeyboardButtonProps={{*/}
            {/*                                'aria-label': 'change date',*/}
            {/*                            }}*/}
            {/*                        />*/}
            {/*                    </Grid>*/}
            {/*                    <Grid item xs={6}>*/}
            {/*                        <FormControl className={classes.nativeSelect} error={isEmpty(nationality)}>*/}
            {/*                            <InputLabel>Nationality</InputLabel>*/}
            {/*                            <Select*/}
            {/*                                onChange={(e) => {*/}
            {/*                                    setNationality(e.target.value.toUpperCase())*/}
            {/*                                }}*/}
            {/*                                label="Nationality"*/}
            {/*                                value={nationality}*/}
            {/*                            >*/}
            {/*                                {getCountrySelect()}*/}
            {/*                            </Select>*/}
            {/*                        </FormControl>*/}
            {/*                    </Grid>*/}
            {/*                    <Grid item xs={6}>*/}
            {/*                        <FormControl className={classes.nativeSelect} error={isEmpty(countryOfResidence)}>*/}
            {/*                            <InputLabel>Country Of Residence</InputLabel>*/}
            {/*                            <Select*/}
            {/*                                onChange={(e) => {*/}
            {/*                                    setCountryOfResidence(e.target.value.toUpperCase())*/}
            {/*                                }}*/}
            {/*                                label="Country Of Residence"*/}
            {/*                                value={countryOfResidence}*/}
            {/*                            >*/}
            {/*                                {getCountrySelect()}*/}
            {/*                            </Select>*/}
            {/*                        </FormControl>*/}
            {/*                    </Grid>*/}
            {/*                    <Grid item xs={6}>*/}
            {/*                        <TextField label="Company Number"*/}
            {/*                                   error={isEmpty(companyNumber) || !v.matches(companyNumber, "^[0-9]*$")}*/}
            {/*                                   onChange={(e) => {*/}
            {/*                                       setCompanyNumber(e.target.value)*/}
            {/*                                   }}*/}
            {/*                        />*/}
            {/*                    </Grid>*/}
            {/*                    <Grid item xs={6}>*/}
            {/*                        <TextField label="Personal Email" error={isEmpty(email) || !isEmail(email)}*/}
            {/*                                   onChange={(e) => {*/}
            {/*                                       setEmail(e.target.value)*/}
            {/*                                   }}*/}
            {/*                        />*/}
            {/*                    </Grid>*/}
            {/*                </Grid>*/}
            {/*                <Grid item xs={12} className={classes.sectionTitle}>*/}
            {/*                    <Typography className={classes.title}>Kyc Proof Images</Typography>*/}
            {/*                    <Typography className={classes.warning}>All fields are required</Typography>*/}
            {/*                </Grid>*/}
            {/*                <Grid container className={classes.sectionContainer} justifyContent="space-between">*/}
            {/*                    <Grid item xs={4}>*/}
            {/*                        <Typography className={classes.title}>Identity Proof</Typography>*/}
            {/*                        {getPdfOrImageElements(charityInfo.kycIdentityProof, "kycIdentityProof", kycIdentityProofRef)}*/}
            {/*                    </Grid>*/}
            {/*                    <Grid item xs={4}>*/}
            {/*                        <Typography className={classes.title}>Association Articles Proof</Typography>*/}
            {/*                        {getPdfOrImageElements(charityInfo.kycAssociationArticlesProof, "kycAssociationArticlesProof", kycAssociationArticlesProofRef)}*/}
            {/*                    </Grid>*/}
            {/*                    <Grid item xs={4}>*/}
            {/*                        <Typography className={classes.title}>Registration Proof</Typography>*/}
            {/*                        {getPdfOrImageElements(charityInfo.kycRegistrationProof, "kycRegistrationProof", kycRegistrationProofRef)}*/}
            {/*                    </Grid>*/}
            {/*                </Grid>*/}
            {/*                <Grid item xs={12} style={{textAlign: 'center'}} mt={5}>*/}
            {/*                    <Button type="submit" variant="contained" color="primary"*/}
            {/*                            className={classes.submitButton}*/}
            {/*                            disabled={isDisabledSubmitButton()}>*/}
            {/*                        <Typography>Submit</Typography>*/}
            {/*                    </Button>*/}
            {/*                </Grid>*/}
            {/*            </MuiPickersUtilsProvider>*/}
            {/*        </Grid>*/}
            {/*    </form>*/}
            {/*</Grid>*/}
            <Grid className="root" container xs={12} sm={12} md={7} lg={12}>
                <Grid item xs={12}>
                    <Typography className={classes.header}>Formulaire d'inscription de votre association</Typography>
                </Grid>
                <Grid item xs={12} className={classes.banner}>
                    <div>
                        <Typography
                            style={{color: '#5D5D5D', fontSize: '21px', textAlign: 'center'}}>iDareYou®</Typography>
                        <Typography style={{color: '#5D5D5D', fontSize: '32px', textAlign: 'center'}}>Ces documents permettront de vérifier votre association et ouvrir votre compte iDareYou</Typography>
                        <Typography style={{color: '#5D5D5D', fontSize: '32px', fontWeight: 600, textAlign: 'center'}}>Ils ne sont et ne seront jamais partagés avec un tiers extérieur.</Typography>
                    </div>
                </Grid>
                <form className={classes.form} onSubmit={(e) => {
                    handleOnSubmit(e)
                }}>
                    <Grid container>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid item xs={12} className={classes.section}>
                                <Grid item xs={6}>
                                    <Typography className={classes.sectionTitle}>1. A propos de l'association (ces données apparaitront dans l'application)</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography className={classes.warning}>Tous les champs sont obligatoires</Typography>
                                </Grid>
                            </Grid>

                            <Grid item xs={6}>
                                <TextField error={isEmpty(charityInfo.name)} value={charityInfo.name || ''}
                                           onChange={(e) => {
                                               setCharityInfo({...charityInfo, name: e.target.value})
                                           }} label="Nom de l'association" placeHolder="Nom de l'association"/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    error={isEmpty(charityInfo.email) || !isEmail(charityInfo.email)}
                                    value={charityInfo.email || ''} label="Courriel de l'association" placeHolder="Courriel de l'association"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl className={classes.nativeSelect}>
                                    <InputLabel>Catégorie de l'association</InputLabel>
                                    <Select
                                        multiple
                                        error={charityInfo.categoryIds.length === 0}
                                        onChange={handleSelectCategories}
                                        value={charityInfo.categoryIds}
                                        input={<Input/>}
                                        renderValue={(selected) => getSelectedCategoryNames(selected).join(', ')}
                                    >
                                        {charityCategories.map((category) => (
                                            <MenuItem key={category.name.fr ? category.name.fr : category.name}
                                                      value={category.id}>
                                                <Checkbox checked={charityInfo.categoryIds.includes(category.id)}/>
                                                <ListItemText
                                                    primary={category.name.fr ? category.name.fr : category.name}/>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} className={classes.textArea}>
                                <Typography style={{fontSize: 16}}>Description de l'association</Typography>
                                <TextField
                                    error={isEmpty(charityInfo.description)}
                                    value={charityInfo.description}
                                    onChange={(e) => {
                                        setCharityInfo({...charityInfo, description: e.target.value})
                                    }}
                                    placeholder="Description de l'association"
                                    variant="outlined"
                                    multiline
                                    minRows={4}
                                    maxRows={4}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl className={clsx(classes.nativeSelect, classes.charityAvatarSelect)}>
                                    <Grid item xs={12} className={classes.section}>
                                        <Typography className={classes.sectionTitle}>2. Logo de l'association</Typography>
                                        <Typography className={classes.warning}>Tous les champs sont requis</Typography>
                                    </Grid>
                                    <Grid container className={classes.sectionContainer}>
                                        <Box className={classes.imageBox} onClick={() => {
                                            handleOnClickFile(avatarRef)
                                        }}>
                                            <input type="file"
                                                   accept="image/png, image/jpeg, image/jpg, application/pdf"
                                                   style={{display: 'none'}} ref={avatarRef} onChange={(e) => {
                                                handOnChangeAvatar(e.target.files)
                                            }}/>
                                            {charityInfo.avatar.url ? (
                                                    <img className={classes.image} src={charityInfo.avatar.url}/>) :
                                                <SvgIcon className={classes.svgIcon}>
                                                    <DownloadIcon/>
                                                </SvgIcon>}
                                        </Box>
                                    </Grid>
                                </FormControl>

                            </Grid>
                            <Grid container className={classes.section}>
                                <Grid item xs={6}>
                                    <Typography className={classes.sectionTitle}>3. Informations bancaires (ces données ne sont pas conservées par
                                        nos équipes mais directement envoyées à Mangopay)</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography className={classes.warning}>Tous les champs sont requis</Typography>
                                </Grid>


                            </Grid>
                            <Grid container className={classes.sectionContainer}>
                                <Grid item xs={6}>
                                    <TextField error={isEmpty(bankAccountOwnerAddress)} value={bankAccountOwnerAddress}
                                               onChange={(e) => {
                                                   setBankAccountOwnerAddress(e.target.value)
                                               }} label="Adresse postale de votre banque"/>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField error={isEmpty(bankAccountCity)} onChange={(e) => {
                                        setBankAccountCity(e.target.value)
                                    }} label="Ville de votre banque"/>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        error={isEmpty(bankAccountPostalCode) || !v.matches(bankAccountPostalCode, "^[0-9]*$")}
                                        onChange={(e) => {
                                            setBankAccountPostalCode(e.target.value)
                                        }} label="Code postal de votre banque"/>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl className={classes.nativeSelect} error={isEmpty(bankAccountCountry)}>
                                        <InputLabel>Pays de votre banque</InputLabel>
                                        <Select
                                            label="Pays de votre banque"
                                            value={bankAccountCountry}
                                            onChange={(e) => {
                                                setBankAccountCountry(e.target.value.toUpperCase())
                                            }}
                                        >
                                            {getCountrySelect()}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField label="Nom du propriétaire du compte bancaire" error={isEmpty(bankAccountOwnerName)}
                                               value={bankAccountOwnerName}
                                               onChange={(e) => {
                                                   setBankAccountOwnerName(e.target.value)
                                               }}
                                               autoComplete="on"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField label="BIC" error={isEmpty(bankAccountIdentifierCode)}
                                               value={bankAccountIdentifierCode}
                                               onChange={(e) => {
                                                   setBankAccountIdentifierCode(e.target.value)
                                               }}
                                               autoComplete="on"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField label="IBAN" error={isEmpty(bankAccountNumber)}
                                               value={bankAccountNumber}
                                               onChange={(e) => {
                                                   setBankAccountNumber(e.target.value)
                                               }}
                                               autoComplete="on"
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} className={classes.section}>
                                <Grid item xs={6}>
                                    <Typography className={classes.sectionTitle}>4. Informations sur votre association (ces données ne sont pas conservées
                                        par nos équipes mais directement envoyées à Mangopay)</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography className={classes.warning}>Tous les champs sont requis</Typography>
                                </Grid>

                            </Grid>

                            <Grid container className={classes.sectionContainer}>
                                <Grid item xs={6}>
                                    <TextField error={isEmpty(address)} value={address} onChange={(e) => {
                                        setAddress(e.target.value)
                                    }} label="Adresse de l'association"/>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField error={isEmpty(city)} onChange={(e) => {
                                        setCity(e.target.value)
                                    }} label="Ville"/>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField error={isEmpty(postalCode) || !v.matches(postalCode, "^[0-9]*$")}
                                               onChange={(e) => {
                                                   setPostalCode(e.target.value)
                                               }} label="Code postal"/>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl className={classes.nativeSelect} error={isEmpty(country)}>
                                        <InputLabel>Pays</InputLabel>
                                        <Select
                                            label="Pays"
                                            value={country}
                                            onChange={(e) => {
                                                setCountry(e.target.value.toUpperCase())
                                            }}
                                        >
                                            {getCountrySelect()}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField label="Prénom du représentant légal de l'association" error={isEmpty(firstName)}
                                               onChange={(e) => {
                                                   setFirstName(e.target.value)
                                               }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField label="Nom du représentant légal de l'association" error={isEmpty(lastName)}
                                               onChange={(e) => {
                                                   setLastName(e.target.value)
                                               }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <KeyboardDatePicker
                                        id="date-picker-dialog"
                                        input
                                        value={birthday}
                                        onChange={(date) => {
                                            setBirthday(new Date(date).valueOf())
                                        }}
                                        maxDate={datePickerMaxDate}
                                        format="dd/MM/yyyy"
                                        margin="normal"
                                        label="Date de naissance du représentant légal de l'association"
                                        InputProps={{readOnly: true}}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl className={classes.nativeSelect} error={isEmpty(nationality)}>
                                        <InputLabel>Nationalité du représentant légal de l'association</InputLabel>
                                        <Select
                                            onChange={(e) => {
                                                setNationality(e.target.value.toUpperCase())
                                            }}
                                            label="Nationalité du représentant légal de l'association"
                                            value={nationality}
                                        >
                                            {getCountrySelect()}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl className={classes.nativeSelect} error={isEmpty(countryOfResidence)}>
                                        <InputLabel>Pays de résidence du représentant légal  </InputLabel>
                                        <Select
                                            onChange={(e) => {
                                                setCountryOfResidence(e.target.value.toUpperCase())
                                            }}
                                            label="Pays de résidence du représentant légal"
                                            value={countryOfResidence}
                                        >
                                            {getCountrySelect()}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField label="Numéro SIREN ou SIRET"
                                               error={isEmpty(companyNumber) || !v.matches(companyNumber, "^[0-9]*$")}
                                               onChange={(e) => {
                                                   setCompanyNumber(e.target.value)
                                               }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField label="Email de l'association ou de la personne en charge de la récolte des dons" error={isEmpty(email) || !isEmail(email)}
                                               onChange={(e) => {
                                                   setEmail(e.target.value)
                                               }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} className={classes.section}>
                                <Typography className={classes.sectionTitle}>5. Documents à envoyer</Typography>
                                <Typography className={classes.warning}>Tous les champs sont requis</Typography>
                            </Grid>
                            <Grid container className={classes.sectionContainer} justifyContent="space-between">
                                <Grid item xs={4}>
                                    <Typography className={classes.proofTitle}>Pièce d'identité (Passeport, Carte d'identité ou permis de conduire en cours
                                        de validité) du représentant légal</Typography>
                                    {getPdfOrImageElements(charityInfo.kycIdentityProof, "kycIdentityProof", kycIdentityProofRef)}
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography className={classes.proofTitle}>Statuts de l'association</Typography>
                                    {getPdfOrImageElements(charityInfo.kycAssociationArticlesProof, "kycAssociationArticlesProof", kycAssociationArticlesProofRef)}
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography className={classes.proofTitle}>Publication au journal officiel des associations et fondations d'entreprises</Typography>
                                    {getPdfOrImageElements(charityInfo.kycRegistrationProof, "kycRegistrationProof", kycRegistrationProofRef)}
                                </Grid>
                            </Grid>
                            <Grid item xs={12} className={classes.termConditions}>
                                <Checkbox
                                    checked={isTermConditionChecked}
                                    onChange={() => {setIsTermConditionChecked(!isTermConditionChecked)}}
                                    onCheck
                                    color='primary'
                                />
                                <Typography>
                                    J'accepte les <a target="_blank" rel="noopener noreferrer" href={MANGO_PAY_TERM_CONDITIONS_URL}>conditions de Mangopay & iDareYou </a>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} style={{textAlign: 'center'}} mt={5}>
                                <Button type="submit" variant="contained" color="primary"
                                        className={classes.submitButton}
                                        disabled={isDisabledSubmitButton()}>
                                    <Typography>Envoyer</Typography>
                                </Button>
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </Grid>
                </form>
            </Grid>
        </Layout>
    )
}
