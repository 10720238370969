import _ from 'lodash';
import {createBrowserHistory} from 'history';

export const intellisense = (dataSource, keyWord, key) => {
    var results = [];

    if (keyWord.length === 0) return dataSource;

    const re = new RegExp(_.escapeRegExp(keyWord), 'i');

    const isMatch = result => re.test((key) ? result[key] : result);

    results = _.filter(dataSource, isMatch);

    return results;

}

export const objectToURLParams = (obj, filter = true) => {
    let params = '';
    const f = filter ? 'filter' : '';
    for (var key in obj) {
        if (params !== "") {
            params += "&";
        }
        if (key === 'where') {
            for (let k in obj[key]) {
                if (params !== "") {
                    params += "&";
                }
                const operator = obj[key][k].op;
                const value = obj[key][k].value;
                params += (operator !== '=') ? `${f}[where][${k}][${operator}]=${value}` : `${f}[where][${k}]=${value}`
            }
        } else if (key === 'limit' || key === 'skip') {
            params += `filter[${key}]=${encodeURIComponent(obj[key])}`;
        }
    }
    return params;
}

export const removeEmptyField = (obj) => {
    const newObj = {}
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            if (obj[key] !== '') {
                newObj[key] = obj[key]
            }
        }
    }
    return newObj;
}

export const browserHistory = createBrowserHistory();

