import httpService from './http.service';
import config from '../environment/config';

class ChallengeService {
    base_url = `${config.api_url}/challenges/public`;
    getById(id) {
        const filter = '{"fields": ["id", "name", "description", "photo"]}'
        return httpService.get(`${this.base_url}/${id}?filter=${filter}`)
    }
}
export default new ChallengeService()


