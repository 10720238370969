import httpService from './http.service';
import config from '../environment/config';

class CharityOrgService {
    base_url = `${config.api_url}/charityorgs`;
    findWithEmail (email) {
        const url = `${this.base_url}?filter={"where":{"email": "${email}"}}` ;
        return httpService.get(url);
    }

    findWithEmailGetProofsOnly (email) {
        const url = `${this.base_url}?filter={"where":{"email": "${email}"}, "fields": ["proofs"]}` ;
        return httpService.get(url);
    }
    updateCharity (data,charityId) {
        const url = `${this.base_url}/${charityId}`
        return httpService.patch(url, data);
    }

    updateSelf(data)  {
        const url = `${this.base_url}/update-self`;
        return httpService.patch(url, data);
    }
}

export default new CharityOrgService()