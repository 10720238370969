import React from 'react';
import {Route, Router, Switch} from 'react-router-dom';
import './App.scss';
import {browserHistory} from './common/helper';

import AppLoader from './components/AppLoader';
import {createTheme, ThemeProvider} from '@material-ui/core';
import useQuery from "./hooks/useQuery";
import {useDispatch} from "react-redux";
import {saveToken} from './slices/authentication.slice'
import * as _ from 'lodash'
import ROUTES from "./config/routes";

export default function App() {
  const dispatch = useDispatch();
  const queryParams = useQuery();
  const {token} = queryParams;
  if (!_.isEmpty(token)) {
    dispatch(saveToken(token))
  }
  const theme = createTheme({
    palette: {
      primary: {
        main: '#f57f17',
        contrastText: '#fff'
      },
      secondary:{
        main: '#11cb5f'
      }
    },
  });
    return (
      <ThemeProvider theme={theme}>
        <div id="app">
            <Router history={browserHistory}>
              <Switch>
                {
                  ROUTES.map((route) => {
                        return (
                            <Route component={route.component} exact={route.exact} path={route.path}/>
                        );
                      }
                  )}
                }
              </Switch>
            </Router>
          <AppLoader/>
        </div>
      </ThemeProvider>
    )
}
