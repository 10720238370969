import Swal from "sweetalert2";

class SweetAlertService {
    async error(message, text) {
        const data = {
            title: message,
            icon: 'error',
            timer: 3000,
            showConfirmButton: false,
            timerProgressBar: true,
        }
        if (text) {
            data.text = text;
        }
        await Swal.fire(data)
    }

    async success(message) {
        await Swal.fire({
            title: message,
            icon: 'success',
            timer: 3000,
            showConfirmButton: false,
            timerProgressBar: true,
        })
    }
}

export default new SweetAlertService()