import axios from 'axios';
import config from '../environment/config';
import jwtTokenHelper from "../helpers/jwt.token.helper"
import swalService from "./swal.service"
class HttpService {
    instance = null;
    http() {
        return this.instance !== null ? this.instance : this.initInstance();
    }
    initInstance() {
        const {api_url} = config;
        const accessToken = jwtTokenHelper.getToken();
        const instance = axios.create({
            baseURL: api_url,
            timeout: 20000,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${accessToken}`
            },
        });
        instance.interceptors.response.use(this.handleSuccess, this.handleError);
        this.instance = instance;
        return this.instance;
    }
    setToken(token) {
        this.instance.defaults.headers.common['Authorization'] = token;
    }
    deleteToken() {
        delete this.instance.defaults.headers.common['Authorization'];
    }
    handleSuccess(response) {
        return response;
    }
    handleError(error) {
        error = error.response.data.error;
        if (['auth.token.expired', 'auth.token.invalid'].includes(error.message)) {
            swalService.error('Your session has been expired. Please contact admin for more information').then(() => {
              window.location.href = '/'
            });
            return Promise.reject(error);
        }
        swalService.error(error.message);
        return Promise.reject(error);
    }
    async get(url, params = {}, option) {
        const res =  await this.http().get(url, {
            ...option,
            params: {
                ...params,
            },
        });
        return res.data;
    }

    async delete(url, data, option) {
        const res = await this.http().delete(url, {
            ...option,
            data,
        });
        return res.data;
    }

    async put(url, data, option) {
        const res = await this.http().put(url, data, {
            ...option,
        });
        return res.data;
    }
    async post(url, data, option) {
        const res = await this.http().post(url, data, {
            ...option,
        });
        return res.data;
    }

    async patch(url, data, option) {
        const res = await this.http().patch(url, data, {
            ...option,
        });
        return res.data;
    }
}
export default new HttpService()
