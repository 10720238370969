import React, {useEffect, useRef, useState} from 'react'
import {useHistory} from 'react-router-dom';
import {Box, Button, Grid, makeStyles, SvgIcon, Typography} from '@material-ui/core'
import Layout from "../../Template/DefaultLayout";
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider,} from '@material-ui/pickers';
import charityOrgService from '../../../services/charity-org.service'
import fileService from '../../../services/file.service'
import Swal from "sweetalert2";
import {FILE_CONTAINERS, KYC_PROOF_TYPES, KYC_STATUSES, REDUX_SLICES} from "../../../constant";
import {ReactComponent as DownloadIcon} from "../../../assets/icons/download.svg";
import {useDispatch, useSelector} from "react-redux";
import {setLoading} from "../../../slices/app.slice";
import swalService from '../../../services/swal.service'
import {getReduxState} from "../../../slices";
import jwtTokenHelper from '../../../helpers/jwt.token.helper'
import useQuery from "../../../hooks/useQuery";
import config from "../../../environment/config";
import AmazonS3URI from "amazon-s3-uri";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        "& .MuiSelect-select:focus": {
            backgroundColor: '#FFF',
        },
    },
    title: {
        fontSize: '16px',
        fontWeight: 600,
        marginTop: theme.spacing(1)
    },
    form: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1, 0, 0, 0),
            width: '80%',
        },
    },
    nativeSelect: {
        margin: theme.spacing(1, 0, 0, 0),
        width: '80%',
    },
    submitButton: {
        width: '12em',
        height: '3em',
        fontSize: '16px'
    },
    imageBox: {
        cursor: 'pointer',
        maxWidth: '230px !important',
        maxHeight: '250px',
        minHeight: '250px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#F5F5F5',
        width: '100%',
        height: '100%'
    },
    image: {
        objectFit: 'cover',
        objectPosition: 'center',
        width: '50%',
        minHeight: '50%'
    },
    svgIcon: {
        minWidth: '30px',
        minHeight: '30px'
    },
}));
export default function EditKycCharity (props) {
    const classes = useStyles();
    const history = useHistory();
    const {isLoading} = getReduxState(REDUX_SLICES.APP, useSelector);
    const dispatch = useDispatch();
    const qsParams = useQuery();
    const kycIdentityProofRef = useRef(null);
    const [accessToken, setAccessToken] = useState("");
    const [charityInfo, setCharityInfo] = useState({
        avatar: {
            url: ''
        },
        kycIdentityProof: {
            url: ''
        },
        kycAssociationArticlesProof: {
            url: ''
        },
        kycRegistrationProof: {
            url: ''
        },
        categoryIds: [],
    });
    const kycAssociationArticlesProofRef = useRef(null);
    const kycRegistrationProofRef = useRef(null);

    const getRejectedOrRefusedProof = (kycDocumentType) => {
        if (charityInfo.proofs) {
            return charityInfo.proofs.filter(p => [KYC_STATUSES.REJECTED, KYC_STATUSES.REFUSED].includes(p.status));
        }
        return []
    }

    const getImage = async (url) => {
        if (url && url.indexOf('https') < 0) {
            return config.api_url + '/Files' + url
        }
        const {key} = AmazonS3URI(url);
        const container = key.split('/')[0];
        const fileName = key.split('/')[1];
        if (container !== FILE_CONTAINERS.KYC) {
            return url;
        }
        const data = await fileService.getPreSignedUrlDownload(container, fileName);
        return data.url;
    }

    useEffect(async () => {

        const token = jwtTokenHelper.getToken()
        // if (!token) {
        //     await swalService.error('Your session has been expired. Please contact admin for more information')
        //     history.push({
        //         pathname: '/',
        //     })
        // }
        const {email} = qsParams;

        charityOrgService.findWithEmailGetProofsOnly(email).then(async (res) => {
            const charity = res[0];
            if (charity && !!!charity.proofs.filter(p => [KYC_STATUSES.REJECTED, KYC_STATUSES.REFUSED].includes(p.status)).length) {
                Swal.fire({
                    title: 'There is no rejected kyc document to edit',
                    icon: 'error',
                    timer: 3000,
                    showConfirmButton: false,
                    timerProgressBar: true,
                }).then(() => {
                    history.push({
                        pathname: '/',
                    })
                })
            }
            charity.kycIdentityProof = charity.kycIdentityProof ? await getImage(charity.kycIdentityProof) : "";
            charity.kycAssociationArticlesProof = charity.kycAssociationArticlesProof ? await getImage(charity.kycAssociationArticlesProof) : "";
            charity.kycRegistrationProof = charity.kycRegistrationProof ? await getImage(charity.kycRegistrationProof) : "";
            setCharityInfo({
                id: charity.id,
                kycIdentityProof: {
                    url: charity.kycIdentityProof,
                    fileType: charity.kycIdentityProof.includes("pdf") ? "application/pdf" : "",
                    fileName: charity.kycIdentityProof.split('/')[4]
                },
                kycAssociationArticlesProof: {
                    url: charity.kycAssociationArticlesProof,
                    fileType: charity.kycAssociationArticlesProof.includes("pdf") ? "application/pdf" : "",
                    fileName: charity.kycAssociationArticlesProof.split('/')[4]
                },
                kycRegistrationProof: {
                    url: charity.kycRegistrationProof,
                    fileType: charity.kycRegistrationProof.includes("pdf") ? "application/pdf" : "",
                    fileName: charity.kycRegistrationProof.split('/')[4]
                },
                proofs: charity.proofs
            })
        })
    }, [])
    const isDisabledSubmitButton = () => {
        return (
            (isRejectedOrRefusedProof(KYC_PROOF_TYPES.IDENTITY_PROOF) ? !charityInfo.kycIdentityProof.data : false)
            || (isRejectedOrRefusedProof(KYC_PROOF_TYPES.REGISTRATION_PROOF) ? !charityInfo.kycRegistrationProof.data : false) || (isRejectedOrRefusedProof(KYC_PROOF_TYPES.ASSOCIATION_ARTICLES_PROOF)) ? !charityInfo.kycAssociationArticlesProof.data : false)
    }
    const handleOnClickFile = (ref, kycDocumentType) => {
        if (isRejectedOrRefusedProof(kycDocumentType)) {
            ref.current.click();
        }
    }
    const isRejectedOrRefusedProof = (kycDocumentType) => {
        if (charityInfo.proofs && !!charityInfo.proofs.length) {
            const proofs = charityInfo.proofs.filter(p => p.eventType === kycDocumentType && [KYC_STATUSES.REJECTED, KYC_STATUSES.REFUSED].includes(p.status));
            return !!proofs.length;
        }
        return false;
    }
    const getProofElement = (kycDocumentType) => {
        if (isRejectedOrRefusedProof(kycDocumentType) && kycDocumentType === KYC_PROOF_TYPES.IDENTITY_PROOF) {
            return (
            <Grid item xs={6}>
                <Typography className={classes.title}>Identity Proof</Typography>
                <Box className={classes.imageBox} onClick={() => {
                    handleOnClickFile(kycIdentityProofRef, KYC_PROOF_TYPES.IDENTITY_PROOF)
                }}>
                    <input type="file" accept="image/png, image/jpeg, image/jpg, application/pdf"
                           style={{display: 'none'}} ref={kycIdentityProofRef} onChange={(e) => {
                        handleOnChangeIdentityProof(e.target.files)
                    }}/>
                    {charityInfo.kycIdentityProof.url ?
                        (charityInfo.kycIdentityProof.fileType === "application/pdf" ?
                            <div>
                                <Typography
                                    className={classes.title}>{charityInfo.kycIdentityProof.fileName}</Typography>
                            </div>
                            :
                            <img className={classes.image} src={charityInfo.kycIdentityProof.url}/>) :
                        <SvgIcon className={classes.svgIcon}>
                            <DownloadIcon/>
                        </SvgIcon>}
                </Box>
            </Grid>)
        }
        if (isRejectedOrRefusedProof(kycDocumentType) && kycDocumentType === KYC_PROOF_TYPES.REGISTRATION_PROOF) {
            return (<Grid item xs={6}>
                <Typography className={classes.title}>Registration Proof</Typography>
                <Box className={classes.imageBox} onClick={() => {
                    handleOnClickFile(kycRegistrationProofRef, KYC_PROOF_TYPES.REGISTRATION_PROOF)
                }}>
                    <input type="file" accept="image/png, image/jpeg, image/jpg, application/pdf"
                           style={{display: 'none'}} ref={kycRegistrationProofRef} onChange={(e) => {
                        handleOnChangeRegistrationProof(e.target.files)
                    }}/>
                    {charityInfo.kycRegistrationProof.url ? (charityInfo.kycRegistrationProof.fileType === "application/pdf" ?
                        <div>
                            <Typography
                                className={classes.title}>{charityInfo.kycRegistrationProof.fileName}</Typography>
                        </div>
                        :
                        <img className={classes.image} src={charityInfo.kycRegistrationProof.url}/>) :
                        <SvgIcon className={classes.svgIcon}>
                            <DownloadIcon/>
                        </SvgIcon>}
                </Box>
            </Grid>)
        }
        if (isRejectedOrRefusedProof(kycDocumentType) && kycDocumentType === KYC_PROOF_TYPES.ASSOCIATION_ARTICLES_PROOF) {
            return (<Grid item xs={6}>
                <Typography className={classes.title} style={{minWidth: '100px'}}>Association Articles
                    Proof</Typography>
                <Box className={classes.imageBox} onClick={() => {
                    handleOnClickFile(kycAssociationArticlesProofRef, KYC_PROOF_TYPES.ASSOCIATION_ARTICLES_PROOF)
                }}>
                    <input type="file" accept="image/png, image/jpeg, image/jpg, application/pdf"
                           style={{display: 'none'}} ref={kycAssociationArticlesProofRef} onChange={(e) => {
                        handleOnChangeAssociationArticlesProof(e.target.files)
                    }}/>
                    {charityInfo.kycAssociationArticlesProof.url ? (charityInfo.kycAssociationArticlesProof.fileType === "application/pdf" ?
                        <div>
                            <Typography
                                className={classes.title}>{charityInfo.kycAssociationArticlesProof.fileName}</Typography>
                        </div>
                        :
                        <img className={classes.image} src={charityInfo.kycAssociationArticlesProof.url}/>) :
                        <SvgIcon className={classes.svgIcon}>
                            <DownloadIcon/>
                        </SvgIcon>}
                </Box>
            </Grid>)
        }
    }

    const handleOnChangeIdentityProof = async (files) => {
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        if (files[0]) {
            const fileSize = Math.round(files[0].size / 1000);
            if (fileSize < 32 || fileSize > 10000) {
                await Swal.fire({
                    title: 'Your file is too small or too large to be read, must be between 32KB and 10MB',
                    icon: 'error',
                    timer: 3000,
                    showConfirmButton: false,
                    timerProgressBar: true,
                })
                files = []
                return;
            }
        }
        reader.onloadend = (readerEvt) => {
            setCharityInfo({
                ...charityInfo,
                kycIdentityProof: {url: reader.result, data: files[0], fileType: files[0].type, fileName: files[0].name}
            });
        };
    }
    const handleOnChangeAssociationArticlesProof = async (files) => {
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        if (files[0]) {
            const fileSize = Math.round(files[0].size / 1000);
            if (fileSize < 1 || fileSize > 10000) {
                await Swal.fire({
                    title: 'Your file is too small or too large to be read, must be between 1KB and 10MB',
                    icon: 'error',
                    timer: 3000,
                    showConfirmButton: false,
                    timerProgressBar: true,
                })
                files = []
                return;
            }
        }
        // console.log('Files', files[0]);
        reader.onloadend = (readerEvt) => {
            // console.log('Blolb', reader.result);
            setCharityInfo({
                ...charityInfo,
                kycAssociationArticlesProof: {
                    url: reader.result,
                    data: files[0],
                    fileType: files[0].type,
                    fileName: files[0].name
                }
            });
        };
    }
    const handleOnChangeRegistrationProof = async (files) => {
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        if (files[0]) {
            const fileSize = Math.round(files[0].size / 1000);
            if (fileSize < 1 || fileSize > 10000) {
                await swalService.error('Your file is too small or too large to be read, must be between 1KB and 10MB')
                files = []
                return;
            }
        }
        // console.log('Files', files[0]);
        reader.onloadend = (readerEvt) => {
            // console.log('Blolb', reader.result);
            setCharityInfo({
                ...charityInfo,
                kycRegistrationProof: {
                    url: reader.result,
                    data: files[0],
                    fileType: files[0].type,
                    fileName: files[0].name
                }
            });
        };
    }
    const uploadKycImages = async () => {
        try {
            const rejectedProofs = getRejectedOrRefusedProof();
            const proofs = [];
            if (!!rejectedProofs.length) {
                const proofTypes = rejectedProofs.map(p => p.eventType);
                proofTypes.forEach((proofType) => {
                    switch (proofType) {
                        case KYC_PROOF_TYPES.IDENTITY_PROOF.toString():
                            proofs.push({proofType: KYC_PROOF_TYPES.IDENTITY_PROOF, file: charityInfo.kycIdentityProof.data});
                            break;
                        case KYC_PROOF_TYPES.ASSOCIATION_ARTICLES_PROOF.toString():
                            proofs.push({proofType: KYC_PROOF_TYPES.ASSOCIATION_ARTICLES_PROOF, file: charityInfo.kycAssociationArticlesProof.data})
                            break;
                        case KYC_PROOF_TYPES.REGISTRATION_PROOF.toString():
                            proofs.push({proofType: KYC_PROOF_TYPES.REGISTRATION_PROOF, file: charityInfo.kycRegistrationProof.data});
                            break;
                    }
                })
            }
            const files = proofs.map(p => p.file);
            const uploadedFileUrls = await fileService.uploadMultipleFile(FILE_CONTAINERS.KYC, files, 'kyc');
            for (const [index, proof] of proofs.entries()) {
                delete proof.file;
                proof.url = uploadedFileUrls[index];
            }
            return proofs;
        } catch (error) {
            let errorData = {
                title: 'Upload Kyc document error',
            }
            if (error.response) {
                console.log('Error', error.response.data);
                console.log('Error mess', error.message);
                console.log('Error data', error.data);
                errorData.text = error.response.data.error.stack;
            }
            await swalService.error(errorData.title, errorData.text)
        }
    }
    const handleOnSubmit = async (e) => {
        e.preventDefault();
        let payload = {};
        delete payload.id;
        await dispatch(setLoading(true))
        const uploadedImages = await uploadKycImages()
        const rejectedProofTypes = uploadedImages.map(p => p.proofType);
        for (const image of uploadedImages) {
            switch (image.proofType) {
                case KYC_PROOF_TYPES.IDENTITY_PROOF:
                    payload.kycIdentityProof = fileService.removeAwsPreSignedUrl(image.url ? image.url : charityInfo.kycIdentityProof.url);
                    continue;
                case KYC_PROOF_TYPES.REGISTRATION_PROOF:
                    payload.kycRegistrationProof = fileService.removeAwsPreSignedUrl(image.url ? image.url : charityInfo.kycRegistrationProof.url);
                    continue;
                case KYC_PROOF_TYPES.ASSOCIATION_ARTICLES_PROOF:
                    payload.kycAssociationArticlesProof = fileService.removeAwsPreSignedUrl(image.url ? image.url : charityInfo.kycAssociationArticlesProof.url);
                    continue;
            }
        }
        payload.proofs = charityInfo.proofs;
        for (const proof of payload.proofs) {
            if (rejectedProofTypes.includes(proof.eventType)) {
                proof.status = KYC_STATUSES.BEING_REVIEWED;
            }
        }
        payload.kycStatus = KYC_STATUSES.BEING_REVIEWED;
        console.log('Res proof', payload);
        try {
            await charityOrgService.updateSelf(payload);
            await swalService.success('Edit kyc document successfully');
            await dispatch(setLoading(false))
            history.push({
                pathName: '/'
            })
        } catch(error) {
            await dispatch(setLoading(false))
        }
    }

    return (
        <Layout>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <form className={classes.form} onSubmit={(e) => {
                        handleOnSubmit(e)
                    }}>
                        <Typography className={classes.title}>Kyc Proof Images</Typography>
                        <Grid container>
                                {getProofElement(KYC_PROOF_TYPES.IDENTITY_PROOF)}
                                {getProofElement(KYC_PROOF_TYPES.ASSOCIATION_ARTICLES_PROOF)}
                                {getProofElement(KYC_PROOF_TYPES.REGISTRATION_PROOF)}
                        </Grid>
                        <Box display='flex' style={{justifyContent: 'center'}} mt={5}>
                            <Button type="submit" variant="contained" color="primary" className={classes.submitButton}
                                    disabled={isDisabledSubmitButton()}>
                                <Typography>Submit</Typography>
                            </Button>
                        </Box>

                    </form>
                </MuiPickersUtilsProvider>
        </Layout>
    )
}

