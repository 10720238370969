import SharingContainer from "../containers/Sharing";
import DownloadDataContainer from "../containers/DownloadData";
import VerifyAccount from "../containers/Auth/VerifyAccount";
import ResetPassword from "../containers/Auth/ResetPassword";
import ChallengeDetail from "../containers/Challenges/Detail";
import EditCharity from "../containers/Charity/Edit";
import EditKycCharity from "../containers/Charity/KYCEdit";
import Homepage from "../containers/Homepage";

export default [
    {path: '/sharing/:challenge_file', component: SharingContainer, exact: false},
    {path: '/download-data', component: DownloadDataContainer, exact: true},
    {path: '/verify-account', component: VerifyAccount, exact: true},
    {path: '/reset-password', component: ResetPassword, exact: true},
    {path: '/challenges/:id', component: ChallengeDetail, exact: true},
    {path: '/charity/edit', component: EditCharity, exact: true},
    {path: '/charity/kyc/edit', component: EditKycCharity, exact: true},
    {component: Homepage, exact: true}
]