import React, {useEffect, useState} from 'react'
import {GridRow, Message} from 'semantic-ui-react';
import {verifyCode, verifyMsg} from '../../../constant';
import {isNotEmpty} from '../../../common/customValidator';
import userService from '../../../services/user.service';
import Layout from '../../Template/DefaultLayout';
import './index.scss';
import useQuery from "../../../hooks/useQuery";

export default function VerifyAccount(props) {
    const queryParams = useQuery();
    const [state, setState] = useState({
        confirmError: {},
        userId: '',
        token: '',
        showPasswordForm: false,
        color: false
    });
    useEffect(() => {
        const {token, uid, updated} = queryParams;
        setState({... state,userId: uid, token});
        if (!token || !uid) {
            return props.history.push('/')
        }
        handleVerify({userId: uid, token, updated});
    }, [])


    const handleVerify = (data) => {
        data = data || {
            userId: state.userId,
            token: state.token,
            updated: false
        };

        if (data.userId !== '' && data.token !== '') {
            userService.verifyAccount(data.userId, data.token, data.updated).then(res => {
                setState({
                    ...state,
                    confirmError: {
                        message: verifyMsg.success,
                        code: verifyCode.activated,
                    },
                    color: true
                })
            }).catch(err => {
                const code = err.response && err.response.data.error.code;
                let message = '';
                if (code === verifyCode.not_reset_pwd || code === verifyCode.activated) {
                    message = verifyMsg.activated
                    setState({
                        color: true
                    })
                } else {
                    message = verifyMsg.orther;
                    setState({...state, color: false})
                }
                setState({
                    ...state,
                    confirmError: {
                        code: code,
                        message: message
                    },
                    showPasswordForm: code === verifyCode.not_reset_pwd
                })
            })
        } else {
            setState({
                ...state,
                confirmError: {
                    code: verifyCode.user_not_found,
                    message: verifyMsg.orther,
                    color: false
                }
            })
        }
    };


    const {confirmError, showPasswordForm, color, token} = state;
    return (
        <Layout>
            <GridRow>
                {confirmError && isNotEmpty(confirmError.code) &&
                <Message error={!color} content={confirmError.message}
                         info={color} className='center-text'
                />}

            </GridRow>
        </Layout>
    )
}
