import React from 'react'
import {GridRow} from 'semantic-ui-react';
import Layout from '../Template/DefaultLayout';
import {Box, Typography} from '@material-ui/core'
import AppstoreIcon from '../../assets/icons/appstore_btn.png'
import ChplayIcon from '../../assets/icons/chplay_btn.png'
import LogoImage from '../../assets/icons/icon.png'

export default function Homepage (props) {
    return  (
    <Layout>
        <GridRow>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" maxWidth={700} margin="auto" minHeight={500}>
                <img style={{width: "100px", marginBottom: "50px"}} src={LogoImage}/>
                <Typography variant="h4" align="center">Our Portal is coming soon</Typography>
                <Box marginBottom="30px" display="flex"  marginTop="50px" justifyContent="center" maxWidth={100} alignItems="center">
                    <img style={{width: "", marginRight: "50px"}} src={AppstoreIcon}/>
                    <img style={{width: ""}} src={ChplayIcon}/>
                </Box>
            </Box>
        </GridRow>
    </Layout>
    )
}
