import {createSlice} from '@reduxjs/toolkit'
import {REDUX_SLICES} from "../constant";

const initialState = {
    accessToken: '',
}

export const userSlice = createSlice({
    name: REDUX_SLICES.USER,
    initialState,
    reducers: {

    },
})

// Action creators are generated for each case reducer function
export const { increment, decrement, incrementByAmount } = userSlice.actions

export default userSlice.reducer