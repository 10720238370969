import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import registerServiceWorker from './registerServiceWorker';

import 'semantic-ui-css/semantic.min.css';
import {store} from './store'
import {Provider} from 'react-redux'
import {Router} from 'react-router-dom'
import {browserHistory} from "./common/helper";


ReactDOM.render(<Provider store={store}>
    <Router history={browserHistory}>
        <App />
    </Router>
</Provider>, document.getElementById('root'));
registerServiceWorker();
