import {createSlice} from '@reduxjs/toolkit'
import {REDUX_SLICES} from "../constant";
import jwtTokenHelper from "../helpers/jwt.token.helper"

const initialState = {
    accessToken: '',
}

export const authenticationSlice = createSlice({
    name: REDUX_SLICES.AUTHENTICATION,
    initialState,
    reducers: {
        saveToken: (state, {payload}) => {
            state.accessToken = payload;
            jwtTokenHelper.saveToken(payload.toString())
        }
    },
})

// Action creators are generated for each case reducer function
export const { saveToken } = authenticationSlice.actions

export default authenticationSlice.reducer