import httpService from './http.service';
import config from '../environment/config';


class UserService {
    base_url = `${config.api_url_user}/Users`;
    getRole = '?filter={"include": {"relation":  "roleMapping", "scope": {"include": "role", "fields": ["roleId"]}}}'

    login (bodyData) {
        return httpService.post(`${this.base_url}/login`, bodyData)
    }

    getProfileDetail() {
        return httpService.get(`${this.base_url}/me${this.getRole}`);
    }

    editProfile(userId, bodyData) {
        return httpService.patch(`${this.base_url}/${userId}`, bodyData)
    }

    changePassword(body) {
        return httpService.post(`${this.base_url}/change-password`, body)
    }

    resetPassword (bodyData)  {
        return httpService.post(`${this.base_url}/reset-password`, bodyData)
    }

    downloadData(params) {
        return httpService.get(`${this.base_url}/mydata/download`,{

            ...params
        }, {
            headers: {
                responseType:'blob',
            }
        });
    }

    verifyAccount = (userid, token, updated) => {
        const url = (updated ? `${this.base_url}/confirm-email` : `${this.base_url}/confirm`) + '?uid='+userid+'&token='+token;
        return httpService.get(url);
    }
}

export default new UserService()
