class JwtTokenHelper {
    getToken() {
        return sessionStorage.getItem('accessToken');
    }

    saveToken(accessToken) {
        console.log('Save', accessToken)
        sessionStorage.setItem('accessToken', accessToken)
    }

    removeToken() {
        sessionStorage.clear()
    }
}

export default new JwtTokenHelper()