import React, {Fragment} from 'react'
import {Container} from "@material-ui/core";
import {Link} from 'react-router-dom';
import './styles.scss';
import logo from '../../assets/icons/logo.png';

export default function Layout(props) {
    return (
        <Fragment>
            <nav className='navbar header-menu'>
                <div className="logo">
                    <Link to='/'>
                        <img width="100%" src={logo}/>
                    </Link>
                </div>
            </nav>
            <Container disableGutters>
                <div className="root-container">
                    <Container maxWidth="lg">
                        {props.children}
                    </Container>
                </div>
            </Container>
        </Fragment>
    )
}
